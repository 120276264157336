<template>
	<section class="permission">
		<!--工具条-->
		<el-col :span="24" class="toolbar">
			<el-form :inline="true" :model="filters">
				<span>
					<el-form-item>
						<el-input v-model.trim="filters.keyword" placeholder="关键词"></el-input>
					</el-form-item>
					<el-form-item>
						<el-select v-model.trim="filters.action" placeholder="请选择">
                            <el-option label="全部" value=""></el-option>
                            <el-option v-for="a in actions" :key="a.value" :label="a.label" :value="a.value"></el-option>
                        </el-select>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="getList(0)" icon="el-icon-search">搜索</el-button>
					</el-form-item>
				</span>
				<el-form-item>
					<el-button type="primary" @click="handleAdd" icon="el-icon-plus">创建</el-button>
				</el-form-item>
			</el-form>
		</el-col>

		<!--列表-->
		<el-table :data="data.list" highlight-current-row v-loading="listLoading" style="width: 100%;">
			<el-table-column prop="id" label="ID" width="100">
			</el-table-column>
			<el-table-column label="标题">
                <template slot-scope="scope">
                    <div>
                        <strong v-if="scope.row.receiver && !scope.row.state">[未读]</strong>
                        {{scope.row.title}}</div>
                    <div class="vHtml" v-html="scope.row.content.substr(0,50)"></div>
				</template>
			</el-table-column>	
			<el-table-column label="接收门店" width="160">
                <template slot-scope="scope">
                    <span v-if="data.store_map[scope.row.receiver]">
                        {{data.store_map[scope.row.receiver].name}}
                    </span>
                    <span v-else>全部门店</span>
				</template>
			</el-table-column>	
			<el-table-column label="类型" width="100">
				<template slot-scope="scope">
                    <div v-for="a in actions" :key="a.value">
                        <span v-if="scope.row.action == a.value">{{a.label}}</span>
                    </div>
				</template>
			</el-table-column>
			<el-table-column prop="createtime" label="发送时间" sortable width="200">
			</el-table-column>
			<el-table-column label="操作" width="160">
				<template slot-scope="scope">
                    <el-button @click="sho(scope.row)" icon="el-icon-view"></el-button>
                    <el-button type="danger" @click="del(scope.row)" icon="el-icon-delete"></el-button>
				</template>
			</el-table-column>
		</el-table>

		<!--工具条-->
		<el-col :span="24" class="toolbar">
			<!--分页-->
			<el-pagination
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:current-page="filters.page"
					:page-sizes="pagesizes"
					:page-size="filters.pagesize"
					layout="total, sizes, prev, pager, next, jumper"
					:total="data.total">
			</el-pagination>
		</el-col>

	</section>
</template>

<script>
import { noticeList, noticeDel } from "../../../api/api";

export default {
    data() {
        return {
            actions: [
                { value: 0, label: "消息通知" },
                { value: 1, label: "续费通知" },
                { value: 2, label: "费率切换" }
            ],
            filters: {
                keyword: "",
                action: "",
                pagesize: 10,
                page: 1
            },
            data: {
                list: [],
                total: 0,
                store_map: {}
            },
            pagesizes: [10, 20, 50, 100], //可选条数
            listLoading: false
        };
    },
    methods: {
        //分页
        handleSizeChange(val) {
            this.filters.pagesize = val;
            this.getList();
        },
        handleCurrentChange(val) {
            this.filters.page = val;
            this.getList();
        },
        //获取用户列表
        getList(val) {
            if (val == "0") this.filters.page = 1;
            this.listLoading = true;
            noticeList(this.filters).then(res => {
                this.listLoading = false;
                if (res.code == 1) {
                    Object.assign(this.data, res.data);
                } else {
                    this.$notify({
                        title: "错误",
                        message: res.msg,
                        type: "error"
                    });
                }
            });
        },
        sho: async function(o) {
            this.$alert(`<div style="max-height: 50vh;overflow: auto;">${o.content}</div>`, o.title, {
                dangerouslyUseHTMLString: true
            });
        },
        del: async function(o) {
            await this.$confirm("确认删除吗？", "提示", {});
            try {
                await noticeDel({ id: o.id });
                this.getList();
            } catch (e) {
                this.$message.error(e.message);
            }
        },
        handleAdd: function() {
            this.$router.push({ name: "通知发送" });
        }
    },
    mounted() {
        this.getList();
    }
};
</script>

<style lang="scss" scoped>
@import "../../../styles/permission.scss";
.vHtml{ color:#999; font-size: 12px;
    ::v-deep p{ color:#999; font-size: 12px }
}
.el-message-box__message img {
    width: 100%;
}
</style>